import { reactive } from 'vue'

export const swiperBreakpoints = reactive({
  768: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  1200: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  1280: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  1440: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
})

export function getContainerWidth(isCompact) {
  const screenWidth = window.innerWidth
  let containerWidth = screenWidth

  if (screenWidth >= 576 && screenWidth < 768)
    containerWidth = 540

  else if (screenWidth >= 768 && screenWidth < 992)
    containerWidth = 720

  else if (screenWidth >= 992 && screenWidth < 1200)
    containerWidth = 960

  else if (screenWidth >= 1200 && screenWidth < 1400)
    containerWidth = 1140

  else if (screenWidth >= 1400 && screenWidth < 1600)
    containerWidth = 1320

  else if (screenWidth >= 1600)
    containerWidth = 1500

  if (isCompact) {
    if (screenWidth >= 1200 && screenWidth < 1400)
      containerWidth = Math.floor(1140 * 0.7)

    else if (screenWidth >= 1400 && screenWidth < 1600)
      containerWidth = Math.floor(1320 * 0.7)

    else if (screenWidth >= 1600)
      containerWidth = Math.floor(1500 * 0.7)
  }

  return containerWidth
}
