<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { getContainerWidth } from '@/utils'

const props = defineProps({
  compact: {
    type: Boolean,
    default: false,
  },
  alignRight: {
    type: Boolean,
    default: false,
  },
  maxLgNormalize: {
    type: Boolean,
    default: false,
  },
})

const element = ref(null)

const paddingX = ref(0)
const whitespaceX = ref(0)
const left = ref(0)

function getPaddingX() {
  const isTabletOrBigger = window && window.innerWidth >= 768
  return isTabletOrBigger ? 0 : 16
}

function getWhitespaceX() {
  const paddingX = getPaddingX()
  const width = getContainerWidth(props.compact)
  return (window.innerWidth - width) / 2 + paddingX * 2
}

function getLeft() {
  const paddingX = getPaddingX()
  const width = getContainerWidth(props.compact)
  return (window.innerWidth - width) / 2 + paddingX
}

function setCSSVars() {
  paddingX.value = getPaddingX()
  whitespaceX.value = getWhitespaceX()
  left.value = getLeft()

  setTimeout(() => {
    paddingX.value = getPaddingX()
    whitespaceX.value = getWhitespaceX()
    left.value = getLeft()
  }, 0)
}

onMounted(() => {
  if (props.alignRight) {
    setCSSVars()
    window.addEventListener('resize', setCSSVars)
  }
})

onUnmounted(() => {
  if (props.alignRight)
    window.removeEventListener('resize', setCSSVars)
})
</script>

<template>
  <div
    ref="element"
    class="x-container"
    :class="{
      'compact': props.compact,
      'align-right': props.alignRight,
      'max-lg-normalize': props.maxLgNormalize,
    }"
    :style="{
      '--padding-x': `${paddingX}px`,
      '--container-whitespace-x': `${whitespaceX}px`,
      '--container-left': `${left}px`,
    }"
  >
    <slot />
  </div>
</template>

<style scoped>
.x-container {
  --container-width: 100%;
  //--padding-x: 16px;
  //--container-whitespace-x: calc(((100vw - var(--container-width)) / 2) + (var(--padding-x) * 2));
  //--container-left: calc((100vw - var(--container-width)) / 2 + var(--padding-x));
  padding-left: var(--padding-x);
  padding-right: var(--padding-x);
  width: var(--container-width);

  @media (min-width: 576px) {
    @apply mx-auto;
    --container-width: 540px;
  }
  @media (min-width: 768px) {
    --padding-x: 0;
    --container-width: 720px;
  }
  @media (min-width: 992px) {
    --container-width: 960px;
  }
  @media (min-width: 1200px) {
    --container-width: 1140px;
  }
  @media (min-width: 1400px) {
    --container-width: 1320px;
  }
  @media (min-width: 1600px) {
    --container-width: 1500px;
  }

  &.compact {
    @media (min-width: 1400px) {
      --container-width: calc(1320px * 0.7);
    }
    @media (min-width: 1600px) {
      --container-width: calc(1500px * 0.7);
    }
  }

  &.align-right {
    width: calc(100% - var(--container-left));
    margin-right: 0;

    @screen max-sm {
      padding-right: 0;
      width: 100%;
    }

    &.max-lg-normalize {
      @media (min-width: 768px) and (max-width: 1023px) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
</style>
